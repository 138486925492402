<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> MODIFICAR DATOS EMPRESA </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'empresaPanel' }"
                    >Empresa</router-link
                  >
                </p>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12 text-center" v-show="!mostrarContenido">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form
              @submit.prevent="modificar()"
              v-show="mostrarContenido"
              :disabled="processForm"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                    id="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Nombre"
                    v-model="empresa.nombre"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field dense label="Ruc" v-model="empresa.ruc"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    rows="3"
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Direccion"
                    v-model="empresa.direccion"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Telefono"
                    v-model="empresa.telefono"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Whatsapp"
                    v-model="empresa.whatsapp"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Correo"
                    v-model="empresa.correo"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    rows="3"
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Google maps"
                    v-model="empresa.googlemaps"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Facebook"
                    v-model="empresa.facebook"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Instagram"
                    v-model="empresa.instagram"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Twitter"
                    v-model="empresa.twitter"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Linkendin"
                    v-model="empresa.linkedin"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Youtube"
                    v-model="empresa.youtube"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Pinterest"
                    v-model="empresa.pinterest"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <p>Logo:</p>
                  <input
                    style="display: none"
                    type="file"
                    ref="iptLogo"
                    @change.prevent="cargarLogo"
                    accept="image/*"
                  />
                  <div class="custom-my-dropzone">
                    <v-row>
                      <v-col cols="12" class="text-center" v-show="urlLogo === ''">
                        <v-btn
                          :loading="loaderLogo"
                          color="primary"
                          @click="
                            $refs.iptLogo.value = '';
                            $refs.iptLogo.click();
                          "
                        >
                          <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR LOGO
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="text-center" v-show="urlLogo !== ''">
                        <v-btn icon color="red" small @click.prevent="eliminarLogo">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                        <v-img :src="urlLogo" height="200" contain> </v-img>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>

                <!--
                                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                                <p>Favicon:</p>
                                <input style="display:none" type="file" ref="iptFavicon" @change.prevent="cargarFavicon" accept="image/*">
                                <div class="custom-my-dropzone">
                                    <v-row>
                                        <v-col cols="12" class="text-center" v-show="urlFavicon === ''">
                                            <v-btn :loading="loaderFavicon" color="primary" @click="$refs.iptFavicon.value='';$refs.iptFavicon.click()">
                                                <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR FAVICON
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" class="text-center" v-show=" urlFavicon !== '' ">
                                            <v-btn icon color="red" small @click.prevent="eliminarFavicon">
                                                <v-icon>mdi-trash-can</v-icon>
                                            </v-btn>
                                            <v-img :src="urlFavicon" height="200" contain>
                                            </v-img>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                           -->

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      empresa: {
        _method: "PUT",
        idempresa: "",
        nombre: "",
        ruc: "",
        logo: "",
        favicon: "",
        direccion: "",
        telefono: "",
        whatsapp: "",
        correo: "",
        googlemaps: "",
        facebook: "",
        instagram: "",
        twitter: "",
        linkedin: "",
        youtube: "",
        pinterest: "",
      },
      mostrarContenido: false,
      processForm: false,
      listErrors: [],
      alertError: false,
      loaderLogo: false,
      urlLogo: "",
      loaderFavicon: false,
      urlFavicon: "",
    };
  },

  methods: {
    cargarLogo(event) {
      this.loaderLogo = true;
      if (this.urlLogo !== "") {
        this.$toasted.error("Ya hay un archivo cargado");
        this.loaderLogo = false;
        return;
      }

      if (event.target.files.length > 0) {
        let logo = event.target.files[0];
        this.urlLogo = URL.createObjectURL(logo);
        this.empresa.logo = logo;
      }

      this.loaderLogo = false;
    },

    eliminarLogo() {
      this.urlLogo = "";
      this.empresa.logo = "";
    },

    cargarFavicon(event) {
      this.loaderFavicon = true;

      if (this.urlFavicon !== "") {
        this.$toasted.error("Ya hay un archivo cargado");
        this.loaderFavicon = false;
        return;
      }

      if (event.target.files.length > 0) {
        let favicon = event.target.files[0];
        this.urlFavicon = URL.createObjectURL(favicon);
        this.empresa.favicon = favicon;
      }

      this.loaderFavicon = false;
    },

    eliminarFavicon() {
      this.urlFavicon = "";
      this.empresa.favicon = "";
    },

    getDatos() {
      this.mostrarContenido = false;
      this.axios({
        method: "GET",
        url: "api/panel/empresa",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let empresa = response.data.data;

            this.empresa.idempresa = empresa.idempresa;
            this.empresa.nombre = empresa.nombre;
            this.empresa.ruc = empresa.ruc;
            this.empresa.direccion = empresa.direccion;
            this.empresa.telefono = empresa.telefono;
            this.empresa.whatsapp = empresa.whatsapp;
            this.empresa.correo = empresa.correo;
            this.empresa.googlemaps = empresa.googlemaps;
            this.empresa.facebook = empresa.facebook;
            this.empresa.instagram = empresa.instagram;
            this.empresa.twitter = empresa.twitter;
            this.empresa.linkedin = empresa.linkedin;
            this.empresa.youtube = empresa.youtube;
            this.empresa.pinterest = empresa.pinterest;

            if (empresa.logo != null) {
              this.urlLogo = this.assetAWS("perfil_tienda/logo/" + empresa.logo);
            }
            if (empresa.favicon != null) {
              this.urlFavicon = this.assetAWS("perfil_tienda/favicon/" + empresa.favicon);
            }
          }

          this.mostrarContenido = true;
        })
        .catch((error) => {
          this.$toasted.error("Ocurrio un error al buscar este registro", {
            icon: "mdi-close",
          });

          this.$router
            .push({
              name: "empresaPanel",
            })
            .catch(() => {});
        })
        .finally(() => {});
    },

    modificar() {
      this.processForm = true;
      this.alertError = false;
      this.axios({
        method: "POST",
        url: "api/panel/empresa/" + this.empresa.idempresa,
        data: this.serialize(this.empresa),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router
              .push({
                name: "empresaPanel",
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al modificar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
  created() {
    if (!this.validarPermiso("empresa.index")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);

    this.getDatos();
  },
};
</script>
